import { required } from 'vuelidate/lib/validators'
import addresses from '@/assets/validateForms/Address'
import phones from '@/assets/validateForms/Phones'
import emails from '@/assets/validateForms/Emails'

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    detailGroup: ['entity.firstName', 'entity.lastName'],
    emailsPhonesGroup: ['entity.emails', 'entity.phones'],
    addressesGroup: ['entity.addresses'],
    entity: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      ...addresses,
      ...phones,
      ...emails
    }
  }
}
