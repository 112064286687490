<template>
  <div v-if="entity">
    <app-detail-header
      :show-toggle="true"
      :show-new="false"
      :show-delete="entity.isActive && !entity.isNew"
      :show-print="!entity.isNew"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <app-side-menu>
          <aside
            class="menu"
            slot="side-menu">
            <p class="menu-label">
              <span class="title is-6">
                <strong>{{ (entity.firstName)? entity.firstName : 'Name' }} {{ entity.lastName }}</strong>
              </span>
            </p>
            <ul class="menu-list">
              <li>
                <router-link
                  :to="{ name: routeTypes.ContactDetail.name, query: $route.query }"
                  active-class="is-active"
                  exact>
                  <span
                    :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.detailGroup.$error }"
                    :data-badge="$v.detailGroup.$error ? '' : null">
                    Detail Info
                  </span>
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: routeTypes.ContactAsset.name, query: $route.query}"
                  active-class="is-active"
                  append>Links</router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: routeTypes.ContactAddress.name, query: $route.query}"
                  active-class="is-active"
                  append>
                  <span
                    :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.addressesGroup.$error }"
                    :data-badge="$v.addressesGroup.$error ? '' : null">
                    Addresses
                  </span>
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: routeTypes.ContactPhoneEmail.name, query: $route.query}"
                  active-class="is-active"
                  append>
                  <span
                    :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.emailsPhonesGroup.$error }"
                    :data-badge="$v.emailsPhonesGroup.$error ? '' : null">
                    Phones/Emails
                  </span>
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: routeTypes.ContactRemark.name, query: $route.query}"
                  active-class="is-active"
                  append>Remarks</router-link>
              </li>
            </ul>
          </aside>
        </app-side-menu>
      </div>
      <div class="column">
        <router-view
          :entity="entity"
          @toggleActive="onToggleActive" />
      </div>
    </div>
    <unsaved-modal
      :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <save-conflict-modal
      :active.sync="isSaveConflictModalActive"
      @close="closeModal()"
      @reload="reloadData()">
      <p slot="text-title">Change conflict</p>
      <p slot="text-content">The data on the server is newer than the local copy. Please reload local data.</p>
    </save-conflict-modal>
    <confirm-modal
      :active.sync="isDeleteConfirmModalActive"
      @ok="deleteEntity(false, false, deleteAndSave)"
      @cancel="deleteEntity(false, true, false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Contact is in use</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ entity.firstName }} {{ entity.lastName }}</span> is in use. Set to
        <span class="tag is-danger">In-Active</span> anyway?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import ContactService from './ContactService'
import ContactRoutes from './route-types'
import AppDetailHeader from '@/components/AppDetailHeader'
import AppSideMenu from '@/components/AppSideMenu'
import ContactFormValidation from './ContactFormValidation'
// import EventBus from '@/components/EventBus'
import HttpStatus from '@/components/http-status'
import { AppHeaderButtonTypes } from '@/enums'
import { UnsavedModal, SaveConflictModal, ConfirmModal } from '@/components/BulmaModal'
import QuickInvoiceRoutes from '@/views/quickinvoice/route-types'
import SundryCreditorRoutes from '@/views/sundrycreditor/route-types'
import PurchaseOrderRoutes from '@/views/purchaseorder/route-types'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import { ContactPayload } from '@/classes'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'ContactView',
  components: {
    AppDetailHeader,
    AppSideMenu,
    UnsavedModal,
    SaveConflictModal,
    ConfirmModal
  },
  mixins: [ContactFormValidation, StoreMixin],
  props: {
    isNew: Boolean,
    returnUrl: String,
    params: String,
    firstname: String,
    lastname: String,
    assetType: String,
    assetId: String,
    assetName: String
  },
  data() {
    return {
      entity: null,
      dataChanged: false,
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isSkipSave: false,
      isAddNew: false,
      isDeleteConfirmModalActive: false,
      toRoute: null
    }
  },
  computed: {
    validateError() {
      return this.$v.$error || this.$v.detailGroup.$error || this.$v.emailsPhonesGroup.$error || this.$v.addressesGroup.$error
    },
    routeTypes() {
      return ContactRoutes
    }
  },
  async created() {
    if (this.$route.query.mode === 'new') {
      this.$showSpinner('Creating...')
      const payload = new ContactPayload(`${this.firstname} ${this.lastname}`, this.assetId, this.assetType, this.assetName, false)
      await this.addStoreItem(payload)
      this.entity = _cloneDeep(this.currentSnapshot)
      this.persistQueries()
      this.replaceRoute(this.entity.id)
      this.$hideSpinner()
    } else {
      this.getEntity()
    }
  },
  mounted() {},
  beforeDestroy() {
    this.clearSessionStorage()
    this.clearSnapshots(this.entity.id)
  },
  methods: {
    async getEntity(reset = false) {
      this.entity = null
      try {
        this.$showSpinner()
        if (reset) {
          this.clearSnapshots(this.$route.params.contactId)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.$route.params.contactId)
        }
        this.entity = _cloneDeep(this.currentSnapshot)
      } catch (e) {
        // EventBus.$emit('serviceError', e.response.status)
        console.log(e)
        // this.$notification.openMessageXhrError('', e)
      } finally {
        this.$hideSpinner()
      }
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          this.deleteAndSave = true
          this.deleteEntity(true, false, true)
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async onToggleActive() {
      if (this.entity.isActive) {
        // Reserved for future action
      } else {
        this.deleteAndSave = false
        await this.deleteEntity(true, this.entity.isActive, false)
      }
    },
    async deleteEntity(check, value, saveEntity) {
      this.isDeleteConfirmModalActive = false
      const isInUse = check ? await ContactService.assertUsed(this.entity.id) : false
      if (isInUse) {
        this.isDeleteConfirmModalActive = true
      } else {
        this.entity.isActive = value
        if (saveEntity) {
          this.save(true)
        }
      }
    },
    print() {
      const params = Object.assign(
        {
          CompanyID: this.$userInfo.companyId,
          ContactID: this.entity.id
        }
      )
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.assetId = this.entity.id
      emailer.subject = 'Contact Profile Detail'
      emailer.reportName = 'rptTableContactProfileDetails'
      this.addEmailer(emailer)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    async save(isDelete) {
      if (this.$v.$invalid) {
        this.$notification.validationError('Contact')
        return false
      }
      this.$showSpinner()
      try {
        this.saveSnapshot(_cloneDeep(this.entity))
        if (this.entity.isNew) {
          await ContactService.postContact(this.entity)
          this.$notification.success('Save', 'Successful')
          this.dataChanged = false
          await this.getEntity(true)
        } else if (this.snapshotDiff) {
          this.entity.deepDiff = JSON.stringify(this.snapshotDiff)
          await ContactService.putContact(this.entity)
          this.$notification.openNotificationWithType('success', 'Contact', isDelete ? 'Contact set to In-Active' : 'Save successful')
          await this.getEntity(true)
        } else {
          this.$notification.openNotificationWithType('warning', 'Contact', 'No changes detected. Not saved')
        }
        this.$hideSpinner()
        return true
      } catch (e) {
        if (e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError('', e)
        }
        this.$hideSpinner()
        return false
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.returnUrl) {
        if (this.snapshotDiff && !this.isSkipSave) {
          this.isUnsavedModalActive = true
        } else {
          this.$showSpinner('Returning...')
          const url = `${process.env.VUE_APP_ROOT_URI}${this.returnUrl}&params=${this.params}&contactId=${this.$route.params.contactId}`
          this.clearSessionStorage()
          this.clearSnapshots(this.entity.id)
          window.location.href = url
        }
      } else {
        if (this.returnRoute && !_isEmpty(this.returnRoute)) {
          this.$router.push({
            path: this.returnRoute.path
          })
        } else if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else {
          this.$router.push({
            path: ContactRoutes.ContactListView.path
          })
        }
      }
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.toRoute = null
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      if (this.validateError) {
        this.$notification.validationError('Contact save')
      } else {
        var saveResult = await this.save()

        if (saveResult) {
          this.cancel()
        }
      }
    },
    reloadData() {
      this.isSaveConflictModalActive = false
      this.getEntity(true)
    },
    replaceRoute(id) {
      // console.log(this.returnUrl)
      const newMeta = Object.assign(this.$route.meta, {
        isNew: true,
        returnUrl: this.returnUrl,
        params: this.params,
        firstname: this.firstname,
        lastname: this.lastname,
        assetType: this.assetType,
        assetId: this.assetId,
        assetName: this.assetName
      })
      this.$router.replace({
        name: ContactRoutes.ContactDetail.name,
        params: { contactId: id },
        meta: newMeta
      })
    },
    persistQueries() {
      if (this.isNew) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|contact|isNew`, this.isNew)
      }
      if (this.returnUrl) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|contact|returnUrl`, this.returnUrl)
      }
      if (this.params) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|contact|params`, this.params)
      }
      if (this.firstname) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|contact|firstname`, this.firstname)
      }
      if (this.lastname) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|contact|lastname`, this.lastname)
      }
      if (this.assetType) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|contact|assetType`, this.assetType)
      }
      if (this.assetId) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|contact|assetId`, this.assetId)
      }
      if (this.assetName) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|contact|assetName`, this.assetName)
      }
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|contact|isNew`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|contact|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|contact|params`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|contact|firstname`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|contact|lastname`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|contact|assetType`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|contact|assetId`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|contact|assetName`)
    },
    clearSessionStorage() {
      this.removeQueries()
    }
  },
  beforeRouteLeave: function(to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entity))
    // console.log(this.snapshotDiff)
    if (this.snapshotDiff && !this.isSkipSave) {
      this.toRoute = to
      this.$router.replace(from.path)
      this.isUnsavedModalActive = true
    } else {
      if (to.name === QuickInvoiceRoutes.QuickInvoiceDetail.name) {
        if (!this.entity.isNew) {
          this.setQuickInvoiceContactId(this.entity.id)
        }
      }
      if (to.name === SundryCreditorRoutes.SundryCreditorDetail.name) {
        if (!this.entity.isNew) {
          this.setSundryCreditorContact({ contact: { key: this.entity.id, value: `${this.entity.firstName} ${this.entity.lastName}` } })
        }
      }
      if (to.name === PurchaseOrderRoutes.PurchaseOrderDetail.name) {
        if (!this.entity.isNew) {
          this.setPurchaseOrderContact({ contact: { key: this.entity.id, value: `${this.entity.firstName} ${this.entity.lastName}` } })
        }
      }
      this.setReturnRoute({})
      this.clearSnapshots(this.entity.id)
      next()
    }
  }
}
</script>
