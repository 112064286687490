import { required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      form: {
        assetType: 0,
        asset: {
          key: '',
          value: ''
        }
      }
    }
  },
  validations: {
    form: {
      assetType: {
        required
      },
      asset: {
        required
      }
    }
  }
}
