<template>
  <div :class="{ 'tile is-parent': isTiled}">
    <article :class="{ 'tile is-child box detail-page-tile' : isTiled }">
      <p class="title">Links</p>
      <table class="table is-fullwidth is-narrow">
        <thead>
          <tr>
            <th>
              No.
            </th>
            <th>
              Type
            </th>
            <th>
              Name
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(i, idx) in entity.assets"
            v-show="!i.isDeleted"
            :key="idx">
            <td>{{ idx + 1 }}</td>
            <td>{{ assetTypes[i.assetType - 1] }}</td>
            <td>{{ i.name }}</td>
            <td style="text-align:right;">
              <button
                class="button is-primary is-small is-inverted"
                @click="editAsset(i)">
                <span class="icon">
                  <i class="mdi mdi-lead-pencil mdi-24px" />
                </span>
              </button>
              <button
                class="button is-danger is-small is-inverted"
                @click="removeAsset(idx)">
                <span class="icon">
                  <i class="mdi mdi-close mdi-24px" />
                </span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="has-text-right">
        <button
          class="button is-primary tooltip"
          @click="addAsset()"
          data-tooltip="Add new link">
          <span class="icon is-small">
            <i class="mdi mdi-plus mdi-24px" />
          </span>
        </button>
      </div>
    </article>
  </div>
</template>
<script>
import Vue from 'vue'
import AssetTypes from '@/enums/AssetTypes'
import AssetSearchModal from '@/components/AssetSearchModal'

const AssetSearchComponent = Vue.extend(AssetSearchModal)

export default {
  props: {
    entity: null,
    isTiled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cardModal: null
    }
  },
  computed: {
    assetTypes: function() {
      return Object.getOwnPropertyNames(AssetTypes)
      // return AssetTypes
    }
  },
  methods: {
    addAsset() {
      const asset = {
        id: '00000000-0000-0000-0000-000000000000',
        assetType: '1',
        assetId: '',
        name: '',
        isNew: true,
        isDeleted: false
      }

      this.cardModal = new AssetSearchComponent({
        el: document.createElement('div'),
        propsData: { visible: false, assetType: asset.assetType }
      }).$on('ok', res => {
        asset.assetType = res.assetType
        asset.assetId = res.asset.key
        asset.name = res.asset.value

        this.entity.assets.push(asset)
      })
      this.cardModal.visible = true
    },

    editAsset(asset) {
      this.cardModal = new AssetSearchComponent({
        el: document.createElement('div'),
        propsData: { visible: false, assetType: asset.assetType, asset: { key: asset.assetId, value: asset.name } }
      }).$on('ok', res => {
        asset.assetType = res.assetType
        asset.assetId = res.asset.key
        asset.name = res.asset.value
        asset.isDirty = res.isDirty
      })
      this.cardModal.visible = true
    },

    removeAsset(idx) {
      var asset = this.entity.assets[idx]

      if (asset.isNew) {
        this.entity.assets.splice(idx, 1)
      } else {
        asset.isDeleted = true
      }
    }
  }
}
</script>
<style lang="scss">
</style>
