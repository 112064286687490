<template>
  <card-modal :visible="visible"
    :title="'Asset Search'"
    @ok="ok"
    @close="close"
    @cancel="cancel">
    <div class="content"
      style="height: 350px">
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Type</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="form.assetType"
                  @change="typeChanged">
                  <option v-for="(a, b) in assetTypes"
                    :key="b"
                    :value="a">{{ b }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Asset</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <!-- <v-select-validated :v="$v.form.asset" :v-name="'Asset'" :value="form.asset" :options="options" max-height="7.5em" placeholder="Search ..." v-on:search="search" v-on:change="change" label="value"></v-select-validated> -->
              <bulma-autocomplete id="searcher"
                v-model="assetName"
                :data="options"
                @input="search"
                @select="change"
                :keep-first="true"
                :expanded="true"
                :type="$v.form.asset.required? '' : 'is-danger'" />
              <span class="help is-danger"
                v-if="!$v.form.asset.required">Asset is required</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </card-modal>
</template>

<script>
import { CardModal } from '@/components/BulmaModal'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import AssetTypes from '@/enums/AssetTypes'
import _debounce from 'lodash.debounce'

import CompanyService from '@/views/company/CompanyService'
import CustomerService from '@/views/customer/CustomerService'
import ContactService from '@/views/contact/ContactService'
import VendorService from '@/views/vendor/VendorService'
import InsurerService from '@/views/insurer/InsurerService'

import AssetSearchForm from '@/assets/validateForms/AssetSearchForm'

export default {
  components: {
    CardModal,
    BulmaAutocomplete
  },
  mixins: [AssetSearchForm],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    assetType: {
      type: String,
      default: ''
    },
    asset: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      assetName: String,
      options: []
    }
  },
  computed: {
    assetTypes: function() {
      return AssetTypes
    }
  },
  mounted() {
    this.form.assetType = this.assetType
    this.form.asset = this.asset

    if (this.asset && this.asset.value) {
      this.assetName = this.asset.value
    } else {
      this.assetName = ''
    }

    setTimeout(() => {
      document.getElementById('searcher').focus()
    }, 500)
  },
  methods: {
    search: _debounce(async function(key) {
      if (!key) {
        return
      }

      let rs = {}

      switch (this.form.assetType.toString()) {
        case '0': // company
          rs = await CompanyService.searchCompanyByName(key)
          break
        case '1': // customer
          rs = await CustomerService.searchCustomerByName(key)
          break
        case '2': // contact
          rs = await ContactService.searchContactByName(key)
          break
        case '3': // vendor
          rs = await VendorService.searchVendorByName(key)
          break
        case '4': // insurer
          rs = await InsurerService.searchInsurerByName(key)
          break
      }

      this.options = rs.data.list
    }, 500),
    change(val) {
      this.form.asset = val
      console.log(this.form.asset)
    },
    typeChanged() {
      this.assetName = ''
      this.form.asset = null
      this.options = []
    },
    ok() {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        this.$emit('ok', {
          assetType: this.form.assetType,
          asset: {
            key: this.form.asset.key,
            value: this.form.asset.value
          },
          isDirty: this.$v.$dirty
        })
        this.visible = false
      }
    },
    close() {
      // If this is not here
      // Dialog will not show up if [x] or outside of modal is clicked
      this.visible = false
    },
    cancel() {
      this.visible = false
    }
  }
}
</script>
